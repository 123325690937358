const primary = {
  main: "#3B8A0B",
  light: "#8CC63F",
  dark: "#1D4505",
  A100: "#F5B6CE",
  A800: "#9A0A41",
  A900: "#711638",
  A1000: "#39091B",
  contrastText: "#FFF",
}
// const primary = {
//   main: "#E80E61",
//   light: "#F92577",
//   dark: "#BC0C4F",
//   A100: "#F5B6CE",
//   A800: "#9A0A41",
//   A900: "#711638",
//   A1000: "#39091B",
//   contrastText: "#FFF",
// }

const secondary = {
  main: "#FFFFFF",
  light: "#353737",
  dark: "#030303",
  contrastText: "#FFF",
  A50: "#F9F9F9",
  A100: "#ECECEC",
  A200: "#D7D7D7",
  A300: "#ADADAD",
  A400: "#939393",
  A500: "#525252",
  A600: "#2D2D2D",
  A700: "#222222",
}

const info = {
  main: "#2196F3",
  light: "#428CFB",
}

const success = {
  main: "#2CB349",
  light: "#87DE9A",
  dark: "#258039",
  contrastText: "#FFF",
}

const warning = {
  main: "#F9D518",
  light: "#FFE665",
  dark: "#DA6900",
}

const error = {
  main: "#EF0202",
  light: "#FF5151",
  dark: "#B80000",
}

const mode = "dark"

const text = {
  primary: "#FFF",
  secondary: "#6B6B6B",
  disabled: "#3F3F3F",
}

const background = {
  default: "#030303",
}

const magnitude = {
  A100: "#17AEDA",
  A200: success.dark,
  A300: warning.main,
  A400: warning.dark,
  A500: error.dark,
}

export const palette = {
  primary,
  secondary,
  info,
  success,
  warning,
  error,
  mode,
  text,
  background,
  magnitude,
}
