import { IChartData } from "@types"

const AKSU = await ((): Promise<IChartData[]> =>
  import("./AKSU.json").then((res) => res.AKSU) as Promise<IChartData[]>)()
const ANAN = await ((): Promise<IChartData[]> =>
  import("./ANAN.json").then((res) => res.ANAN) as Promise<IChartData[]>)()
const CHAK = await ((): Promise<IChartData[]> =>
  import("./CHAK.json").then((res) => res.CHAK) as Promise<IChartData[]>)()
const GLHA = await ((): Promise<IChartData[]> =>
  import("./GLHA.json").then((res) => res.GLHA) as Promise<IChartData[]>)()
const JANJ = await ((): Promise<IChartData[]> =>
  import("./JANJ.json").then((res) => res.JANJ) as Promise<IChartData[]>)()
const KAJS = await ((): Promise<IChartData[]> =>
  import("./KAJS.json").then((res) => res.KAJS) as Promise<IChartData[]>)()
const KAKU = await ((): Promise<IChartData[]> =>
  import("./KAKU.json").then((res) => res.KAKU) as Promise<IChartData[]>)()
const KAYN = await ((): Promise<IChartData[]> =>
  import("./KAYN.json").then((res) => res.KAYN) as Promise<IChartData[]>)()
const KCHK = await ((): Promise<IChartData[]> =>
  import("./KCHK.json").then((res) => res.KCHK) as Promise<IChartData[]>)()
const KKOL = await ((): Promise<IChartData[]> =>
  import("./KKOL.json").then((res) => res.KKOL) as Promise<IChartData[]>)()
const KYZA = await ((): Promise<IChartData[]> =>
  import("./KYZA.json").then((res) => res.KYZA) as Promise<IChartData[]>)()
const KZSU = await ((): Promise<IChartData[]> =>
  import("./KZSU.json").then((res) => res.KZSU) as Promise<IChartData[]>)()
const MLSU = await ((): Promise<IChartData[]> =>
  import("./MLSU.json").then((res) => res.MLSU) as Promise<IChartData[]>)()
const NKAT = await ((): Promise<IChartData[]> =>
  import("./NKAT.json").then((res) => res.NKAT) as Promise<IChartData[]>)()
const OKTR = await ((): Promise<IChartData[]> =>
  import("./OKTR.json").then((res) => res.OKTR) as Promise<IChartData[]>)()
const TALS = await ((): Promise<IChartData[]> =>
  import("./TALS.json").then((res) => res.TALS) as Promise<IChartData[]>)()
const TKMK = await ((): Promise<IChartData[]> =>
  import("./TKMK.json").then((res) => res.TKMK) as Promise<IChartData[]>)()
const TMCH = await ((): Promise<IChartData[]> =>
  import("./TMCH.json").then((res) => res.TMCH) as Promise<IChartData[]>)()

export const stdbySignals = {
  AKSU,
  ANAN,
  CHAK,
  GLHA,
  JANJ,
  KAJS,
  KAKU,
  KAYN,
  KCHK,
  KKOL,
  KYZA,
  KZSU,
  MLSU,
  NKAT,
  OKTR,
  TALS,
  TKMK,
  TMCH,
}
