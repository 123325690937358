const breakpoints = {
  values: {
    xs: 0,
    sm: 600,
    md: 1280,
    lg: 1500,
    xl: 1850,
  },
}

export { breakpoints }
